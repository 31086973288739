<template>
  <div
    class="banner"
    v-bind:style="{
      height: pathname === 'projects' && 'auto',
      backgroundSize:
        pathname === 'projects' || pathname === 'why-us' ? 'cover' : 'contain'
    }"
  >
    <div class="banner__container col-11 col-sm-10 m-auto">
      <div class="banner__content" data-aos="fade-up">
        <h2>{{ data.title }}</h2>
      </div>
    </div>
    <div class="container d-flex justify-content-center">
      <iframe
        v-if="pathname === 'why-us'"
        class="ytb_video"
        width="875"
        height="492"
        src="https://www.youtube.com/embed/JAqcbU-hX3E?muted=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <!-- <video
        v-if="pathname === 'why-us'"
        width="100%"
        src="https://i.imgur.com/pgdlZjR.mp4"
        autoPlay
        loop
        muted
        playsInline
      ></video> -->
    </div>
    <img
      v-if="pathname !== 'projects'"
      class="banner__block__img"
      v-bind:class="pathname === 'why-us' && 'd-none'"
      :src="`${data.images}`"
      alt="banner"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Banner',
  data() {
    return {
      pathname: this.$route.path.split('/')[1],
      about_us: this.$t('about_us')
    };
  },
  props: {
    data: {
      type: Object
    }
  },
  mounted() {
    // console.log(this, 'ađasada', this.pathname !== 'why-us');
  },
  watch: {
    lang() {
      this.about_us = this.$t('about_us');
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.banner {
  background: url('/images/bg/bg-about.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding-top: 80px;
  margin-bottom: -150px;
  position: relative;
  z-index: 1;
  .ytb_video {
    max-width: 100%;
  }
  .banner__block__img {
    width: 100%;
    height: auto;
  }
  .banner__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
  }
  .banner__content {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 80px 0 30px;
    h2 {
      position: relative;
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      max-width: 900px;
      z-index: 0;
      margin-bottom: 25px;
      text-transform: none;
      &::first-letter {
        text-transform: uppercase;
      }
      &::after {
        content: '';
        position: absolute;
        background: url('/images/bg/circle-title.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 38px;
        height: 38px;
        z-index: -1;
        top: 0;
        left: -18px;
      }
    }
    p {
      color: var(--grey-text);
      font-size: 18px;
      max-width: 650px;
      white-space: pre-line;
    }
  }
  @media (max-width: 1024px) {
    margin-bottom: -50px;
    .banner__container {
      margin-top: 0;
    }
    .banner__content {
      display: block;
    }
  }
  @media (max-width: 768px) {
    .banner__content {
      h2 {
        font-size: 32px;
      }
    }
  }
}
</style>
